<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon
        name="arrow-left"
        @click="
          productdetails == false
            ? $fun.routerToPage('/user/index')
            : (productdetails = false)
        "
      />
    </template>
    <template #default>
      <div>
        {{ $fanyi("问题产品") }}
      </div>
    </template>
    <!--问题产品 -->
  </headBackBar>
  <div class="content">
    <!-- 问题产品列表 -->
    <van-tabs
      sticky
      offset-top="1.3333rem"
      v-if="productdetails == false"
      v-model:active="active"
      @click-tab="clicktabs"
      title-inactive-color="#000000"
      line-width="30px"
      animated
      title-active-color="#B4272B"
    >
      <van-tab :title="$fanyi('待回复')">
        <div class="main">
          <van-pull-refresh
            v-model="datats.waitforreplyrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="datats.waitforreplyfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                v-for="item in datats.waitforreplylist"
                :key="item.order_sn"
                @click="treatment(item)"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{ item.order_sn }}
                  </div>
                  <div class="state" @click.stop="openChat">
                    <img
                      src="../../../../assets/user-img/13faf8cc-7721-4f7b-a6db-24a0e2481153.png"
                      alt=""
                    />
                    <span> {{ $fanyi("联系担当") }}</span>
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("问题番数") }}：</span>
                  <span class="right-text">{{ item.str }}</span>
                </p>
                <p>
                  <span class="left-title"
                    >{{ $fanyi("问题产品总数量") }}：</span
                  >
                  <span class="right-text">{{ item.total }}</span>
                </p>

                <!-- 立即处理 -->
                <div class="immediate">
                  {{ $fanyi("立即处理") }}
                </div>
              </div>
              <noData v-if="!loading && !datats.waitforreplylist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab :title="$fanyi('待后续处理')">
        <div class="main">
          <van-pull-refresh
            v-model="datats.processingrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="datats.processingfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item item2"
                @click="treatment(item)"
                v-for="item in datats.processinglist"
                :key="item.order_sn"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{ item.order_sn }}
                  </div>
                  <div class="state" @click.stop="openChat">
                    <img
                      src="../../../../assets/user-img/13faf8cc-7721-4f7b-a6db-24a0e2481153.png"
                      alt=""
                    />
                    <span> {{ $fanyi("联系担当") }}</span>
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("问题番数") }}：</span>
                  <span class="right-text">{{ item.str }}</span>
                </p>
                <p>
                  <span class="left-title"
                    >{{ $fanyi("问题产品总数量") }}：</span
                  >
                  <span class="right-text">{{ item.total }}</span>
                </p>
                <!-- <p><span class="left-title">{{ $fanyi('问题提出时间') }}：</span> <span class="right-text">{{
                  '---------'

                }}</span></p>
                <p><span class="left-title">{{ $fanyi('处理时间') }}：</span> <span class="right-text">{{
                  '---------'

                }}</span></p> -->
                <!-- 立即处理 -->
              </div>
              <noData v-if="!loading && !datats.processinglist.length" />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab :title="$fanyi('历史问题产品')">
        <div class="main">
          <van-pull-refresh
            v-model="datats.historicalproductsrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="datats.historicalproductsfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item item3"
                @click="treatment(item)"
                v-for="item in datats.historicalproductslist"
                :key="item.order_sn"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{ item.order_sn }}
                  </div>
                  <div class="state" @click.stop="openChat">
                    <img
                      src="../../../../assets/user-img/13faf8cc-7721-4f7b-a6db-24a0e2481153.png"
                      alt=""
                    />
                    <span> {{ $fanyi("联系担当") }}</span>
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("问题番数") }}：</span>
                  <span class="right-text">{{ item.str }}</span>
                </p>
                <p>
                  <span class="left-title"
                    >{{ $fanyi("问题产品总数量") }}：</span
                  >
                  <span class="right-text">{{ item.total }}</span>
                </p>
                <!-- <p><span class="left-title">{{ $fanyi('问题提出时间') }}：</span> <span class="right-text">{{
                  '---------'

                }}</span></p>
                <p><span class="left-title">{{ $fanyi('处理时间') }}：</span> <span class="right-text">{{
                  '---------'

                }}</span></p>
                <p><span class="left-title">{{ $fanyi('问题产品完成时间') }}：</span> <span class="right-text">{{
                  '---------'

                }}</span></p> -->
                <!-- 立即处理 -->
              </div>
              <noData
                v-if="!loading && !datats.historicalproductslist.length"
              />
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
    <!--问题产品明细 -->
    <div class="detail" v-if="productdetails == true">
      <div class="item" v-for="item in details.list" :key="item.shop_id">
        <!-- 头部 -->
        <div class="top-title">
          <img src="../../../../assets/user-img/矢量智能对象.png" alt="" />
          <div>
            {{ $fanyi("番号") }}：<span>#{{ item.sorting }}</span>
          </div>
        </div>
        <!-- 内容区域 -->
        <div class="context">
          <van-image lazy-load :src="item.pic" @click="$lookImg(item.pic)">
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div class="right">
            <div class="text">
              {{ item.goods_title }}
            </div>
            <!-- 属性 -->
            <div class="Attributes" @click="showedeil(item)">
              {{ processingintent(item.detail) }}
            </div>
            <!-- 数量 -->
            <div class="num">
              <div class="left">
                {{
                  $fun.RMBNumSegmentation($fun.ceil(item.now_price)) +
                  $fanyi("元")
                }}
                <span
                  >({{
                    $fun.JPYNumSegmentation(
                      $fun.ceil(item.now_price * $store.state.exchangeRate)
                    ) + $fanyi("円")
                  }})</span
                >
              </div>
              <div class="right">
                <span>X {{ item.now_num }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 价格 -->
        <div class="price">
          {{ $fanyi("总价") }}；
          <p>
            {{
              $fun.RMBNumSegmentation(
                $fun.ceil(item.now_num * item.now_price)
              ) + $fanyi("元")
            }}<span
              >({{
                $fun.JPYNumSegmentation(
                  $fun.ceil(
                    item.now_num * item.now_price * $store.state.exchangeRate
                  )
                ) + $fanyi("円")
              }})</span
            >
          </p>
        </div>
        <!-- 问题图片 -->
        <div class="title">{{ $fanyi("问题图片") }}：</div>
        <div class="img-box">
          <van-image
            lazy-load
            v-for="(i, index) in item.problem_goods_image"
            :key="index"
            :src="i"
            @click="$lookImg(i)"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
        <!-- 问题详情 -->
        <div class="title">{{ $fanyi("问题详情") }}：</div>
        <div class="Businessdetails">{{ item.description_translate }}</div>
        <div class="title">{{ $fanyi("问题产品数量") }}：</div>
        <div class="Businessdetails">{{ item.problem_num }}</div>
        <div class="title">{{ $fanyi("处理意见") }}：</div>
        <textarea
          v-if="item.status == '2'"
          type="text"
          v-autoHeightForLineBreak
          v-model="item.client_deal"
          :placeholder="$fanyi('请输入处理意见')"
          name=""
          id=""
        />
        <div v-else class="Businessdetails">{{ item.client_deal }}</div>
        <!--处理意见  -->
      </div>
    </div>
    <div
      class="amend"
      v-if="
        productdetails == true && details.list && details.list[0].status == 2
      "
    >
      <div class="xiugai" @click="process">{{ $fanyi("立即处理") }}</div>
    </div>
  </div>
  <detailsAllShow ref="detailshow" />
  <!-- 内容区域 -->
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
import detailsAllShow from "../../../../components/detailsAllShow/index.vue";
import { useRoute } from "vue-router";
import noData from "./components/nodata.vue";
// 问题产品明细是否展示
const route = useRoute();
// 控制问题商品详情是否展示
const productdetails = ref(false);
// 问题产品详情数据
const details = ref(null);
const { proxy } = getCurrentInstance();
const active = ref(0); // 待回复 0  待后续处理 1 历史问题产品
const state = ref("");
const pageSize = ref(20);
if (route.query.type == "pendingProcessing") {
  active.value = 1;
}
Toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
});
// table 点击事件
const clicktabs = (val) => {
  active.value = val.name;
};
const loading = ref(false);

const tablecount = ref([
  {
    count: 0,
  },
  {
    count: 0,
  },
  {
    count: 0,
  },
]);
// // 获取顶部table数量
// const gettablecount = async () => {
//   const res = await proxy.$api.problemGoodsOrderNum()
//   if (res.code !== 0) {
//     Toast.fail(res.msg)
//     Toast.clear()
//   }
//   tablecount.value = res.data

// }
// gettablecount()
// 打开聊天框
const openChat = () => {
  // 打开聊天弹窗
  proxy.$store.commit("changeshowChat", !proxy.$store.state.showChat);
};
// 定义数据
const datats = ref({
  // 待回复数据
  waitforreplylist: [], // 数据
  waitforreplypage: 0, // 页码
  waitforreplyfinished: false, // 触底
  waitforreplyrefreshing: false, // 下拉下滑
  // 待后续处理
  processinglist: [], //
  processingpage: 0, //
  processingfinished: false, //
  processingrefreshing: false, //
  // 历史问题产品
  historicalproductslist: [], // /
  historicalproductspage: 0,
  historicalproductsfinished: false, //
  historicalproductsrefreshing: false, //
});
// 加载数据

const onLoad = async () => {
  // 首先进行判断
  let page = 0;
  if (active.value == 0) {
    state.value = "待回复";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (datats.value.waitforreplyfinished == true) {
      datats.value.waitforreplyrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (datats.value.waitforreplylist.length == 0) {
      datats.value.waitforreplypage = 1;
    } else {
      datats.value.waitforreplypage += 1;
    }
    page = datats.value.waitforreplypage;
  }
  if (active.value == 1) {
    state.value = "待后续处理";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (datats.value.processingfinished == true) {
      datats.value.processingrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (datats.value.processinglist.length == 0) {
      datats.value.processingpage = 1;
    } else {
      datats.value.processingpage += 1;
    }
    page = datats.value.processingpage;
  }
  if (active.value == 2) {
    state.value = "已完成";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (datats.value.historicalproductsfinished == true) {
      datats.value.historicalproductsrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (datats.value.historicalproductslist.length == 0) {
      datats.value.historicalproductspage = 1;
    } else {
      datats.value.historicalproductspage += 1;
    }
    page = datats.value.historicalproductspage;
  }

  // 获取订单列表
  const res = await proxy.$api.problemGoodsOrderList({
    status: state.value,
    page: page,
    pageSize: pageSize.value,
  });
  if (res.code != 0) {
    if (active.value == 0) {
      datats.value.waitforreplyrefreshing = false;
      datats.value.waitforreplyfinished = true;
    }
    if (active.value == 1) {
      orderdata.value.processingrefreshing = false;
      datats.value.processingfinished = true;
    }
    if (active.value == 2) {
      orderdata.value.historicalproductsrefreshing = false;
      datats.value.historicalproductsfinished = true;
    }
    Toast.clear();
    loading.value = false;
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  if (active.value == 0) {
    // 判断是下刷新海是触底  临时保存
    if (datats.value.waitforreplyrefreshing == true) {
      datats.value.waitforreplylist = [
        ...res.data?.data,
        ...datats.value.waitforreplylist,
      ];
    } else {
      datats.value.waitforreplylist = [
        ...datats.value.waitforreplylist,
        ...res.data?.data,
      ];
    }
    datats.value.waitforreplylist.forEach((item) => {
      // console.log(item);
      item.total = 0;
      item.list.forEach((i) => {
        console.log(i.problem_num);
        item.total += i.problem_num;
        if (item.str == undefined) {
          item.str = i.sorting;
        } else {
          item.str = item.str + "," + i.sorting;
        }
      });
    });
    loading.value = false;
    datats.value.waitforreplyrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      datats.value.waitforreplylist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      datats.value.waitforreplyfinished = true;
    }
  }
  if (active.value == 1) {
    // 判断是下刷新海是触底
    if (datats.value.processingrefreshing == true) {
      datats.value.processinglist = [
        ...res.data?.data,
        ...datats.value.processinglist,
      ];
    } else {
      datats.value.processinglist = [
        ...datats.value.processinglist,
        ...res.data?.data,
      ];
    }
    datats.value.processinglist.forEach((item) => {
      console.log(item);
      item.total = 0;
      item.list.forEach((i) => {
        console.log(i.problem_num);
        item.total += i.problem_num;
        if (item.str == undefined) {
          item.str = i.sorting;
        } else {
          item.str = item.str + "," + i.sorting;
        }
      });
    });
    loading.value = false;
    datats.value.processingrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      datats.value.processinglist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      datats.value.processingfinished = true;
    }
  }
  if (active.value == 2) {
    // 判断是下刷新海是触底
    if (datats.value.historicalproductsrefreshing == true) {
      datats.value.historicalproductslist = [
        ...res.data?.data,
        ...datats.value.historicalproductslist,
      ];
    } else {
      datats.value.historicalproductslist = [
        ...datats.value.historicalproductslist,
        ...res.data?.data,
      ];
    }
    datats.value.historicalproductslist.forEach((item) => {
      console.log(item);
      item.total = 0;
      item.list.forEach((i) => {
        console.log(i.problem_num);
        item.total += i.problem_num;
        if (item.str == undefined) {
          item.str = i.sorting;
        } else {
          item.str = item.str + "," + i.sorting;
        }
      });
    });
    loading.value = false;
    datats.value.historicalproductsrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      datats.value.historicalproductslist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      datats.value.historicalproductsfinished = true;
    }
  }
  Toast.clear();
};
// 问题产品列表下拉加载
const onRefresh = () => {
  onLoad();
};
// 点击立即处理
const treatment = (item) => {
  (details.value = item), (productdetails.value = true);
  console.log(details.value);
};
// 商品详情立即处理按钮
const process = async () => {
  Toast.loading({
    duration: 0,
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
  });
  const arr = details.value.list.reduce((acc, item) => {
    console.log(acc);
    acc.push({
      problem_goods_id: item.problem_goods_id,
      client_deal: item.client_deal,
    });
    item.client_deal = "";
    return acc;
  }, []);
  const res = await proxy.$api.questionoverBatch({
    over: arr,
  });
  if (res.code !== 0) {
    return Toast.fail(
      res.msg.slice(-6) == "处理意见缺失"
        ? proxy.$fanyi("提交失败，当前有商品未填写处理意见")
        : proxy.$fanyi(res.msg)
    );
    // Toast.clear();
  }
  datats.value.waitforreplypage = 0;
  datats.value.waitforreplylist = [];
  productdetails.value = false;
  datats.value.waitforreplyfinished = false;
  Toast.clear();
};
// 处理商品属性
const processingintent = (arr) => {
  let str = "";
  arr.forEach((item) => {
    str += item.value + ";";
  });
  return str;
};
const detailshow = ref(null);
const showedeil = (item) => {
  detailshow.value.open(item);
};
// 复制单号
const copySn = (order_sn) => {
  navigator.clipboard.writeText(order_sn);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

:deep().van-overlay {
  opacity: 0.4;
}

:deep() .van-icon-cross {
  width: 19px;
  height: 18px;
  top: 20px;
  right: 28px;
  font-size: 28px;
}

.content {
  width: 100%;
  min-height: 1380px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  :deep() .van-tab__text {
    font-size: 28px;

    font-weight: 500;
  }

  :deep() .van-tabs__line {
    background: #b4272b;
  }

  :deep().van-tabs__wrap {
    height: 68px;
    position: sticky;
    top: 126px;
    z-index: 99;
  }

  // 问题产品列表
  .main {
    width: 100%;
    min-height: 1380px;
    background-color: #f6f6f6;
    padding: 30px;
    padding-top: 20px;

    .item {
      position: relative;
      height: 234px;
      background: #ffffff;
      border-radius: 6px;
      background: #ffffff;
      border-radius: 6px;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 20px;

      .title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 28px;

        .Singlenumbe {
          width: 330px;
          line-height: 35px;
          font-size: 28px;
          white-space: nowrap;
          font-weight: 400;
          color: #000000;
        }

        .state {
          display: flex;
          font-size: 24px;
          margin-top: 50px;

          font-weight: 400;
          color: #b4272b;
          align-items: center;

          span {
            margin-left: 11px;
            font-size: 24px;

            font-weight: 400;
            color: #b4272b;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      p {
        margin-bottom: 10px;
        height: 36px;
        display: flex;
        align-items: center;

        .left-title {
          font-size: 24px;

          font-weight: 400;
          color: #999999;
        }

        .right-text {
          font-size: 24px;

          font-weight: 500;
          color: #000000;
        }
      }

      .immediate {
        position: absolute;
        width: 160px;
        height: 60px;
        right: 30px;
        bottom: 30px;
        background: #b4272b;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        font-size: 28px;

        font-weight: 400;
        color: #ffffff;
        align-items: center;
      }
    }

    .item2 {
      height: 234px;
      background: #ffffff;
      border-radius: 6px;
    }

    .item3 {
      height: 234px;
      background: #ffffff;
      border-radius: 6px;
    }
  }

  //问题产品详情
  .detail {
    width: 100%;
    min-height: 1380px;
    background-color: #f6f6f6;
    padding: 30px;
    padding-top: 20px;

    .item {
      width: 690px;
      min-height: 742px;
      padding: 30px;
      background: #ffffff;
      border-radius: 6px;

      .top-title {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img {
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }

        div {
          font-size: 24px;

          font-weight: 400;
          color: #000000;

          span {
            font-size: 24px;

            font-weight: 400;
            color: #000000;
          }
        }
      }

      .context {
        width: 100%;
        height: 180px;
        display: flex;

        :deep() .van-image {
          width: 180px;
          height: 180px;
          border-radius: 6px;
        }

        .right {
          margin-left: 21px;

          .text {
            width: 430px;
            height: 72px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
            font-size: 28px;

            font-weight: 400;
            color: #000000;
            line-height: 36px;
            margin-bottom: 18px;
          }

          .Attributes {
            width: 350px !important;
            height: 40px;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            font-size: 22px;

            font-weight: 400;
            color: #999999;
            white-space: nowrap; // 强制一行显示
            overflow: hidden; // 超出隐藏
            text-overflow: ellipsis; // 省略号
            // display: flex;

            line-height: 40px;
            padding-left: 11px;

            margin-bottom: 12px;
          }

          .num {
            width: 430px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
              font-weight: 400;
              color: #000000;
              font-size: 24px;

              span {
                font-size: 24px;

                font-weight: 400;
                color: #000000;
              }
            }

            .right {
              span {
                font-size: 20px;

                font-weight: 400;
                color: #999999;
              }
            }
          }
        }
      }

      .price {
        width: 630px;
        height: 36px;
        display: flex;
        margin-top: 20px;
        align-items: center;
        font-size: 24px;

        font-weight: 400;
        margin-bottom: 20px;
        color: #000000;

        p {
          font-size: 24px;
          font-weight: 600;
          color: #b4272b;

          span {
            font-size: 20px;
          }
        }
      }

      .title {
        width: 570px;
        height: 36px;
        font-size: 24px;

        font-weight: 400;
        color: #000000;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .img-box {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 80px;

        :deep() .van-image {
          width: 80px;
          height: 80px;
          border-radius: 6px;
          margin-right: 20px;
        }
      }

      .Businessdetails {
        width: 630px;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 20px;
        color: #3c3c3c;
        padding-left: 19px;
        min-height: 56px;
        background: #fafafa;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        line-height: 54px;
      }

      textarea {
        width: 630px;
        font-size: 22px;
        height: 56px;
        padding: 10px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
      }
    }
  }
}

// 立即修改

.amend {
  position: sticky;
  width: 750px;
  background: #ffffff;
  bottom: 0;
  display: flex;
  justify-content: center;
  // margin-bottom: 20px;
  font-size: 28px;
  padding: 20px 0 40px;
  font-weight: 400;
  color: #ffffff;

  .xiugai {
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
